<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="f-dialog"
    :title="formData.id ? '编辑评论' : '创建评论'"
    width="500px"
  >
    <el-form :model="formData" class="form" label-width="120px" ref="form">
      <el-form-item
        label="用户头像"
        prop="customerAvatarPath"
        :rules="[rules.required()]"
      >
        <div class="avatar-wrap">
          <el-image
            class="avatar"
            fit="cover"
            :src="formData.customerAvatarPath"
            @error="getRandomAvatar"
          ></el-image>
          <svg-icon
            class="change-btn"
            icon-class="reset"
            @click="getRandomAvatar"
          ></svg-icon>
        </div>
      </el-form-item>
      <el-form-item
        label="用户昵称"
        prop="customerName"
        :rules="[rules.required()]"
      >
        <el-input
          placeholder="请输入"
          maxlength="20"
          v-model="formData.customerName"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="评论内容"
        prop="commentContent"
        :rules="[rules.required()]"
      >
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入"
          v-model="formData.commentContent"
        ></el-input>
      </el-form-item>
      <el-form-item label="发布时间" prop="date" :rules="[rules.required()]">
        <el-date-picker
          style="width:100%"
          clearable
          v-model="formData.date"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          placeholder="评论发布日期"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="resetForm">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { createComment } from "@/api/estate";
import rules from "@/utils/rules";
const formData = {
  commentContent: "", // 评论内容 ,
  customerAvatarPath: "", //用户头像
  customerName: "", //用户名
  date: "" //发布时间
};
export default {
  props: {
    id: {
      // 编辑评论时传
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      rules,
      formData: JSON.parse(JSON.stringify(formData)),
      avatarLoadErorrCount: 0 //随机头像加载失败次数
    };
  },
  computed: {
    estateId() {
      return this.$store.state.estateInfo.estateId;
    }
  },
  created() {
    this.getRandomAvatar();
    if (this.id) {
      this.formData.id = this.id;
    }
  },
  methods: {
    //随机获取用户头像
    getRandomAvatar(e) {
      if (this.avatarLoadErorrCount > 50) return; //图片加载失败50次后不再调用此方法，以免进入死循环
      if (e && e.type === "error") {
        this.avatarLoadErorrCount++;
      }
      const random = parseInt(Math.random() * 2030);
      this.formData.customerAvatarPath = `${this.$OSS_URL}/r_user_avatars/avatar%20(${random}).jpg`;
    },
    // 重置表单
    resetForm() {
      this.$refs.form.resetFields(); // 清空表单数据
      this.$emit("update:visible", false);
    },
    //提交表单
    submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        const res = await createComment({
          ...this.formData,
          estateId: this.estateId
        });
        if (res) {
          this.$showSuccess("提交成功");
          this.$emit("refresh");
          this.resetForm();
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.estate-comment {
  min-height: 100%;
  .head {
    .title {
      h3 {
        font-size: 16px;
        line-break: 16px;
        padding-left: 20px;
        border-left: 3px solid $primary;
      }
      p {
        margin-top: 10px;
        padding-left: 20px;
        color: $color9;
      }
    }
  }
  .body {
    margin-top: 15px;
    p {
      color: #aaa;
    }
    .separate {
      margin: 0 10px;
      color: #aaa;
    }
    .el-button {
      padding: 0;
    }
  }
  ::v-deep .body.el-table td {
    vertical-align: top;
  }
}
.tips {
  color: #8691a3;
}
.form {
  .avatar-wrap {
    height: 100px;
    .avatar {
      width: 100px;
      height: 100px;
      background: #eee;
      border: 1px dashed #aaa;
      border-radius: 10px;
    }
    .change-btn {
      margin-left: 10px;
      font-size: 20px;
      cursor: pointer;
      color: #999;
      &:active {
        opacity: 0.75;
      }
    }
  }
}
.filter {
  display: flex;
  justify-content: space-between;
}
</style>
